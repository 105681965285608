import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import { Frontmatter } from '../../templates/template-types'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  PageTitle,
  StaffCard,
  TitleBorder
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../../components/layout/layout'
import SEO from '../../components/seo'


type Node = {
  frontmatter: Frontmatter
}

type Edge = {
  node: Node
}

type AllMdx = {
  edges: Edge[]
}

type Data = {
  allMdx: AllMdx
}

type StaffDirectoryPageProps = {
  data: Data
}

type Type =
  | 'firstName'
  | 'lastName'
  | 'title'

type Order = {
  firstName: number,
  lastName: number,
  title: number
}

const comparison = (a: string, b: string) => {
  if (a.toUpperCase() > b.toUpperCase()) {
    return 1
  }

  if (a.toUpperCase() < b.toUpperCase()) {
    return -1
  }

  return 0
}

const reverseComparison = (a: string, b: string) => {
  if (a.toUpperCase() < b.toUpperCase()) {
    return 1
  }

  if (a.toUpperCase() > b.toUpperCase()) {
    return -1
  }

  return 0
}

const isNegative = (type: Type, order: Order) => order[type] === 1

const getNonZeroValue = (negative: boolean) => {
  return negative ? -1 : 1
}

const sortArray = (edges: Edge[], type: Type, order: Order): Edge[] => {
  return edges.sort((a: Edge, b: Edge) => {
    const compare: number = type === 'firstName' ? 0 : 1

    const personA: Type =
      a.node.frontmatter[type]
      ||
      a.node.frontmatter['fullName'].split(' ')[compare]

    const personB: Type =
      b.node.frontmatter[type]
      ||
      b.node.frontmatter['fullName'].split(' ')[compare]

    return isNegative(type, order)
      ? reverseComparison(personA, personB)
      : comparison(personA, personB)
  })
}


const StaffDirectoryPage = ({ data }: StaffDirectoryPageProps) => {
  const [active, setActive] = useState<number>(1)
  const [staff, setStaff] = useState<Edge[]>(data.allMdx.edges)
  const [order, setOrder] = useState<Order>({firstName: 0, lastName: 0, title: 0})


  //type parameter must be either 'firstName', 'lastName', or 'title'
  const sort = (type: Type) => {
    const negative: boolean = isNegative(type, order)
    const nonZeroValue = getNonZeroValue(negative)

    let options: Order = {
      'firstName': 0,
      'lastName': 0,
      'title': 0
    }

    //search sort type value will be either 1 or -1, the other fields will always be 0
    options[type] = nonZeroValue

    const sortedStaff: Edge[] = sortArray(staff, type, order)

    setStaff(sortedStaff)

    setOrder({
      firstName: options['firstName'],
      lastName: options['lastName'],
      title: options['title']
    })
  }

  useEffect(() => {
    sort('lastName')
  }, [])

  return (
    <Layout>
      <SEO
        title="Meet the BSCS team"
        description="BSCS employs science educators, research scientists, and many other dedicated staff who are committed to transforming science education."
        canonicalUrl="https://bscs.org/about/staff-directory/"
      />
      <div className="px-20">
        <div className="pb-10 pt-2">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link className='inline-anchor' to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              About
            </BreadcrumbItem>
            <BreadcrumbItem>
              Staff Directory
            </BreadcrumbItem>
          </Breadcrumb>
          <div className="mt-6">
            <PageTitle>Staff Directory</PageTitle>
          </div>
          {/* <TitleBorder /> */}
          <div className="border-t-3 mt-1 border-bscs-indigo-1000 rounded max-w-screen-xl mx-auto" />
        </div>
        <div className='flex flex-wrap mt-8 justify-end'>
          <Button
            className='mr-2 mt-2'
            onClick={() => {
              sort('firstName')
              setActive(0)
            }}
            title='Sort by First Name'
            variant='blue'
          >
            {active === 0 ? <i className='fad fa-circle' /> : <i className='fal fa-circle' />}&nbsp;&nbsp;Sort by First Name
          </Button>
          <Button
            className='mr-2 mt-2'
            onClick={() => {
              sort('lastName')
              setActive(1)
            }}
            title='Sort by Last Name'
            variant='blue'
          >
            {active === 1 ? <i className='fad fa-circle' /> : <i className='fal fa-circle' />}&nbsp;&nbsp;Sort by Last Name
          </Button>
          <Button
            className='mt-2'
            onClick={() => {
              sort('title')
              setActive(2)
            }}
            title='Sort by Title'
            variant='blue'
          >
            {active === 2 ? <i className='fad fa-circle' /> : <i className='fal fa-circle' />}&nbsp;&nbsp;Sort by Title
          </Button>
        </div>
        {/* <div className="border-bscs-blue-500 border-t-2 mt-8" /> */}
        <div className='flex flex-wrap justify-center mt-8'>
          {
            staff.map((person: Edge, index: number) => {
              const firstName: string = person.node.frontmatter.fullName.split(' ')[0]
              const lastName: string = person.node.frontmatter.fullName.split(' ')[1]

              return (
                <div
                  className='flex justify-between w-full sm:w-1/2 lg:w-1/3 xl:w-1/4'
                  key={`staff-person-${index}`}
                >
                  { person.node.frontmatter.image &&
                    <StaffCard
                      className="flex w-full"
                      imageAlt={person.node.frontmatter.alt}
                      renderImage={
                        <Img
                          alt={person.node.frontmatter.alt}
                          className="rounded-full h-24 w-24"
                          fluid={person.node.frontmatter.image.childImageSharp.fluid}
                          imgStyle={{ objectPosition: "top" }}
                        />
                      }
                      name={person.node.frontmatter.fullName || `${firstName} ${lastName}`}
                      title={person.node.frontmatter.title}
                      profileUrl={person.node.frontmatter.url}
                    />
                  }
                  { !person.node.frontmatter.image &&
                    <StaffCard
                      className="flex w-full"
                      name={person.node.frontmatter.fullName || `${firstName} ${lastName}`}
                      title={person.node.frontmatter.title}
                      profileUrl={person.node.frontmatter.url}
                    />
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    </Layout>
  )
}

export default StaffDirectoryPage

export const staffDirectoryQuery = graphql`
  query staffDirectoryQuery {
    allMdx(
      filter: { frontmatter: { status: {eq: "Ready"}, page: { eq: "staff-directory" }}}) {
      edges {
        node {
          id
          frontmatter {
            firstName
            fullName
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            alt
            lastName
            title
            page
            url
          }
        }
      }
    }
  }
`
